<template>
        <defaultSec :title="'申请列表'">
            <keep-alive>
                <el-form :inline="true" :model="formParam" class="demo-form-inline select-wrap" size="mini">
                    <el-form-item label="类型">
                        <el-select v-model="formParam.optType" placeholder="请选择退换货/补货类型">
                            <el-option label="补发" :value="1"></el-option>
                            <el-option label="换货" :value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="订单号">
                        <el-input v-model="formParam.orderNo" placeholder="请输入订单号" clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="initList">查询</el-button>
                    </el-form-item>
                </el-form>
            </keep-alive>
            <tablePagination :total="total" :pageSize="formParam.pageSize" @handlePage="handlePage">
                <el-table :data="tableList" border size="mini">
                    <el-table-column type="expand">
                        <template slot-scope="props">
                            <el-row type="flex" class="expand-flex">
                                <el-col :span="2" class="expand-title">退回商品:</el-col>
                                <el-col class="expand-content">
                                    <el-tag class="tags-success" effect="dark" type="success" v-for="(item,index) in props.row.returnProducts">{{item}}</el-tag>
                                </el-col>
                            </el-row>
                            <el-row type="flex" class="expand-flex">
                                <el-col :span="2" class="expand-title">补发商品:</el-col>
                                <el-col class="expand-content">
                                    <el-tag class="tags-success" effect="dark" type="success" v-for="(item,index) in props.row.products">{{item}}</el-tag>
                                </el-col>
                            </el-row>
                        </template>
                    </el-table-column>
                    <el-table-column type="index" label="序号"></el-table-column>
                    <el-table-column prop="originOrderNo" label="原单号"></el-table-column>
                    <el-table-column prop="newOrderNo" label="新单号"></el-table-column>
                    <el-table-column prop="createUserName" label="申请人"></el-table-column>
                    <el-table-column prop="createTime" label="申请时间"></el-table-column>
                    <el-table-column prop="consigneeRemark" label="补发原因"></el-table-column>
                    <el-table-column prop="consignee" label="收货信息"></el-table-column>
                    <el-table-column prop="deliverDate" label="期望配送日期"></el-table-column>
                    <el-table-column label="申请状态">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.formStatus == 0" type="info">作废</el-tag>
                            <el-tag v-if="scope.row.formStatus == 1" type="warning">申请中</el-tag>
                            <el-tag v-if="scope.row.formStatus == 2" type="success">申请通过</el-tag>
                            <el-tag v-if="scope.row.formStatus == 3" type="danger">驳回</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="120">
                        <template slot-scope="scope">
<!--                            <el-button type="info" v-if="scope.row.formStatus == 1" plain size="mini" @click="modifyDeliverDate(scope.row.id,scope.row.deliverDate)">修改配送日期</el-button>-->
                            <el-button type="success" plain size="mini" @click="approch(scope.row.id,scope.row.optType)">审批</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </tablePagination>
            <el-dialog title="审批" :visible.sync="showDialog">
                <el-form label-width="120px">
                    <el-form-item label="是否同意">
                        <el-select v-model="applyApproch.auditRst" placeholder="是否同意">
                            <el-option label="同意" :value="1"></el-option>
                            <el-option label="驳回" :value="0"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="意见">
                        <el-input v-model="applyApproch.auditText" placeholder="请输入文字"></el-input>
                    </el-form-item>
                    <el-form-item label="操作">
                        <el-button type="primary" @click="saveApproch">保 存</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>
            <el-dialog title="修改配送日期" :visible.sync="showDialog2">
                <el-form label-width="120px">
                    <el-form-item label="配送日期">
                        <el-date-picker v-model="deliverDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="操作">
                        <el-button type="primary" @click="saveDeliverDate">保 存</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>
        </defaultSec>
</template>

<script>
    import defaultSec from '@c/defaultSection'
    import tablePagination from '@c/tablePagination'
    export default {
        data() {
            return {
                showDialog: false,
                showDialog2: false,
                formParam:{
                    pageSize:15,
                    currentPage:1,
                    optType:1,
                    orderNo:''
                },
                applyApproch:{
                    applyId:"",
                    optType:"",
                    auditRst:"",
                    auditText:""
                },
                tableList:[],
                deliverApplyId:0,
                deliverDate:"",
                total:0
            }
        },
        components:{
            defaultSec,tablePagination
        },
        mounted(){
            this.initList()
        },
        methods: {
            initList() {
                this.$apiGet('lechun-csms/apply/applyList',this.formParam).then(res => {
                    console.log(res)
                    this.total = res.total;
                    this.tableList = res.list;
                    this.tableList.forEach(r =>{
                        r.consignee = r.consigneeName + " " + r.consigneePhone + "\n" + r.consigneeProvinceName + r.consigneeCityName + r.consigneeAreaName + r.consigneeAddress;
                        r.deliverDate = r.deliverDate.substring(0,10);
                    });
                })
            },
            handlePage(page){
                this.formParam.currentPage = page;
                this.initList()
            },
            approch(id,optType){
                this.showDialog = true;
                this.applyApproch.applyId = id;
                this.applyApproch.optType = optType;
            },
            modifyDeliverDate(id,deliverDate){
                this.showDialog2 = true;
                this.deliverApplyId = id;
                this.deliverDate = deliverDate;
            },
            saveDeliverDate(){
                this.$apiGet('lechun-csms/apply/modifyDeliverDate',{"applyId":this.deliverApplyId,"deliverDate":this.deliverDate}).then(res => {
                    this.$message({
                        showClose: true,
                        message: '成功',
                        type: 'success'
                    });
                    this.showDialog2 = false;
                    this.deliverDate = "";
                    this.deliverApplyId = 0;
                    this.initList();
                });
            },
            saveApproch(){
                this.$apiGet('lechun-csms/apply/audit',this.applyApproch).then(res => {
                    this.$message({
                        showClose: true,
                        message: '成功',
                        type: 'success'
                    });
                    this.showDialog = false;
                    this.initList();
                });
            }
        }
    }
</script>
<style scoped>
.expand-title {
    width: 70px;
    color: #444548;
    line-height: 30px;
    text-align: right;
    margin-right: 10px;
    font-weight: 600;
}

.expand-flex {
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}
</style>
